// Generated by grunt-webfont
// Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css



@font-face {
    font-family:"icon";
    src:url("../../iconfont/icon-618fa3150907426cc13a4b80295aa578.eot");
    font-weight:normal;
    font-style:normal;
}
@font-face {
	font-family:"icon";
	src:url("../../iconfont/icon-618fa3150907426cc13a4b80295aa578.eot");
	src:url("../../iconfont/icon-618fa3150907426cc13a4b80295aa578.eot?#iefix") format("embedded-opentype"),
		url("../../iconfont/icon-618fa3150907426cc13a4b80295aa578.woff") format("woff"),
		url("../../iconfont/icon-618fa3150907426cc13a4b80295aa578.ttf") format("truetype"),
		url("../../iconfont/icon-618fa3150907426cc13a4b80295aa578.svg?#icon") format("svg");
	font-weight:normal;
	font-style:normal;
}


// Bootstrap Overrides
[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family:"icon";
	display:inline-block;
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:optimizeLegibility;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}

// Mixins

.icon-agency, 
.icon-award, 
.icon-caret-left, 
.icon-caret-right, 
.icon-close, 
.icon-download, 
.icon-facebook, 
.icon-link, 
.icon-linkedin, 
.icon-spinner, 
.icon-twitter, 
.icon-xing { 
	&:before {
		font-family:"icon";
		display:inline-block;
		font-weight:normal;
		font-style:normal;
		text-decoration:inherit;
	}
}


// Icons

.icon-agency {
	&:before {
		content:"\f101";
	}
	
}


.icon-award {
	&:before {
		content:"\f102";
	}
	
}


.icon-caret-left {
	&:before {
		content:"\f103";
	}
	
}


.icon-caret-right {
	&:before {
		content:"\f104";
	}
	
}


.icon-close {
	&:before {
		content:"\f105";
	}
	
}


.icon-download {
	&:before {
		content:"\f106";
	}
	
}


.icon-facebook {
	&:before {
		content:"\f107";
	}
	
}


.icon-link {
	&:before {
		content:"\f108";
	}
	
}


.icon-linkedin {
	&:before {
		content:"\f109";
	}
	
}


.icon-spinner {
	&:before {
		content:"\f10a";
	}
	
}


.icon-twitter {
	&:before {
		content:"\f10b";
	}
	
}


.icon-xing {
	&:before {
		content:"\f10c";
	}
	
}

