@import (less) "../../../build/assets/iconfont/icon";

.mejs-controls .mejs-time-rail .mejs-time-loaded {
	background: #ff9900;
}

.mejs-controls .mejs-time-rail .mejs-time-current {
	background: #fff;
}

.tiles {
	max-width: 970px;
	margin: 5px auto 30px auto;
	overflow: hidden;

	.tile {
		display: block;
		width: 25%;
		float: left;
		height: 174px;
		padding: 5px;
		box-sizing: border-box;
		overflow: hidden;
		 -webkit-backface-visibility: hidden;
   		backface-visibility: hidden;
		   
		/*
   		[class*=headline] {
			-ms-word-break: break-all;
			word-break: break-all;
			word-break: break-word;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			hyphens: auto;
		}*/

		&:before {
			cursor: pointer;
		}

		&.big {

			&:before {
				display: none;
			}
		}

		.tile-content {
			background: transparent;
			height: 100%;
			width: 100%;
			position: relative;
			
			//background-image: url(../../images/PIN-1.3-Ziemlich.png); // set via html
			background-repeat: no-repeat;
			background-position: center center;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			cursor: pointer;

			font-size: 14px;
		}

		&.big .tile-content {
			cursor: default;
			background: #ccc;
		}


		.tile-article {
			height: 100%;
			padding: 20px;
			box-sizing: border-box;
			position: absolute;
			left: -9999em;
			top: -9999em;
			color: #000;
		}

		.tile-split {
			overflow: hidden;
		}

		.tile-split-50 {
			width: 50%;
			float: left;
		}

		.tile-split-45 {
			width: 45%;
			float: left;
		}

		.tile-split-55 {
			width: 55%;
			float: left;
		}

		.tile-scroll {
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			float: none;
			//-webkit-transform: translateZ(0); // fix if float none not working
			padding-right: 10px;
			box-sizing: border-box;
		}

		&.big .tile-article {
			position: relative;
			left: auto;
			top: auto;
			background: #ececec;
		}

		// Inner
		
		.tile-images {
			margin-right: 30px;

			img {
				max-width: 100%;
				height: auto;
			}
		}

		.tile-images-dots {
			list-style: none;
			margin: 20px 0 0 0;
			padding: 0;
			text-align: center;

			li {
				margin: 0;
				padding: 0;
				display: inline-block;
				font-size: 15px;
				font-weight: bold;
				margin: 0 5px;
				cursor: pointer;
				color: #ff9300;

				&.slick-active {
					color: #000;
				}
			}
		}

		.tile-meta {
			margin-bottom: 20px;
		}

		.tile-headline {

			h1,h2,h3,h4,h5,h6 {
				font-weight: bold;
				font-size: 15px;
			}
		}

		.tile-audio .tile-headline {

			h1,h2,h3,h4,h5,h6 {
				margin: 0;
				font-size: 15px;
			}
			
		}

		.tile-agency {
			display: block;
			padding-left: 40px;
			padding-bottom: 5px;
			line-height: 20px;
			/*background-image: url('../../images/tile-agency.png');
			background-position: left top;
			background-repeat: no-repeat;*/
			position: relative;
			
			.icon-agency;
			
			&:before {
				position: absolute;
				left: 4px;
				top: 0;
				color: #F69200;
			}
		}

		.tile-award {
			display: block;
			padding-left: 40px;
			padding-bottom: 5px;
			line-height: 20px;
			/*background-image: url('../../images/tile-award.png');
			background-position: left top;
			background-repeat: no-repeat;*/
			
			position: relative;
			
			.icon-award;
			
			&:before {
				position: absolute;
				left: 3px;
				top: 0;
				color: #F69200;
			}
		}

		.tile-link {
			display: block;
			padding-left: 40px;
			padding-bottom: 5px;
			line-height: 20px;
			/*background-image: url('../../images/tile-link.png');
			background-position: left top;
			background-repeat: no-repeat;*/
			position: relative;
			
			.icon-link;
			
			&:before {
				position: absolute;
				left: 2px;
				top: 0;
				color: #F69200;
			}
		}

		.tile-download {
			display: block;
			padding-left: 40px;
			padding-bottom: 5px;
			line-height: 20px;
			/*background-image: url('../../images/tile-download.png');
			background-position: left top;
			background-repeat: no-repeat;*/
			
			position: relative;
			
			.icon-download;
			
			&:before {
				position: absolute;
				left: 3px;
				top: 0;
				color: #F69200;
			}
		}

		.tile-note {
			margin-top: 60px;
			font-style: italic;
		}

		.tile-audios {
			margin-right: 30px;
		}

		.tile-audio {
			position: relative;
			padding-right: 60px;
			-ms-word-break: break-all;
			word-break: break-all;
			word-break: break-word;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			hyphens: auto;
		}

		&.tile-text-audio {

			.tile-audio-play {
				height: 48px;
				width: 48px;
				position: absolute;
				right: 0;
				top: 0;
				background-image: url('../../images/tile-audio-play.svg');
				cursor: pointer;
				opacity: 0.8;

				& > * {
					display: none !important;
				}

				&.playing {
					background-image: url('../../images/tile-audio-stop.svg');
				}
			}

			&:before {
				content: '';
				position: absolute;
				height: 48px;
				width: 48px;
				left: 50%;
				bottom: 20px;
				margin-left: -24px;
				z-index: 1;
				background-image: url('../../images/tile-audio-play.svg');
				background-repeat: no-repeat;
			}
		}

		.tile-audio-text-wrap {
			overflow: hidden;
			width: 100%;
			height: 100%;
			position: relative;
		}

		&.tile-video {

			.mejs-video {
				height: 100% !important;
			}

			.mejs-overlay {
				height: 100% !important;
				width: 100% !important;
			}

			&:before {
				content: '';
				position: absolute;
				height: 48px;
				width: 48px;
				left: 50%;
				bottom: 20px;
				margin-left: -24px;
				z-index: 1;
				background-image: url('../../images/tile-video-play.svg');
				background-repeat: no-repeat;
			}
			
			.tile-video-text {
				position: absolute;
				top: 0;
				right: 0;
				width: 58%;
				background: rgba(0, 0, 0, 0.7);
				padding: 20px;
				box-sizing: border-box;
				bottom: 0;
				height: auto !important;
				
				.open-close {
					height: 26px;
					width: 26px;
					position: absolute;
					top: 50%;
					margin-top: -13px;
					background: rgba(0, 0, 0, 0.7);
					left: -26px;
					cursor: pointer;
					
					i {
						display: block;
						width: 100%;
						height: 100%;
						line-height: 22px;
						text-align: center;
						color: #FF9300;
						font-size: 20px;
						display: inline-block;
						
						&:hover {
							color: #FFA913;
						}
					}
				}
				
				.tile-headline {
					h1,h2,h3,h4,h5,h6 {
						color: #fff;
					}
				}
				
				.tile-meta {
					color: #fff;
				}
				
				.tile-paragraph {
					color: #fff;
				}
				
				.tile-note {
					color: #fff;
				}
			}
		}
	}

	.tile-close {
		width: 20px;
		height: 20px;
		position: absolute;
		right: 0;
		top: 0;
		background: transparent;
		border: 0;
		outline: none;
		padding: 0;
		margin: 0;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		-ms-border-radius: 0px;
		-o-border-radius: 0px;
		border-radius: 0px;
		display: block;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

		.icon-close;

		&:before {
			line-height: 21px;
			width: 100%;
			height: 100%;
			color: #000;
			font-size: 16px;
		}

		&:hover {

			&:before {
				color: #FFA913;
			}
		}

	}
}

.tile-filter {
	max-width: 474px;
	margin: 0 auto;
	text-transform: uppercase;
	
	border: 1px solid #fff;
	margin-bottom: 30px;
	
	.tile-filter-text {
		width: 50%;
		display: block;
		float: left;
		color: #fff;
		padding: 0 10px;
		box-sizing: border-box;
		line-height: 28px;
	}

	.tile-filter-select {
		width: 50%;
		height: 30px;
		line-height: 30px;
		font-size: 15px !important;
		text-transform: uppercase;
		border: 0;
		padding: 0 10px;
		outline: none;
	}
}

/*
@media only screen and (max-width: 980px) {

	.tiles {
		margin: 5px;
	}
}

@media only screen and (max-width: 800px) {

	
	.tiles {
		
		.tile {
			width: 33.33%;
		}
	}
}

@media only screen and (max-width: 480px) {


	.tiles {
		
		.tile {
			width: 50%;

			[class^='tile-split'], [class*=' tile-split']{
			    float: none;
			    width: auto;
			}
		}
	}
}
*/